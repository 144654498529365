//
// jsgrid.scss
//

.jsgrid-cell {
    padding: $table-cell-padding;
}

.jsgrid-grid-header, 
.jsgrid-grid-body, 
.jsgrid-header-row > .jsgrid-header-cell, 
.jsgrid-filter-row > .jsgrid-cell, 
.jsgrid-insert-row > .jsgrid-cell, 
.jsgrid-edit-row > .jsgrid-cell {
    border: none;
}

.jsgrid-alt-row {
    > .jsgrid-cell {
        background: $white;
    }
}

.jsgrid-selected-row {
    >.jsgrid-cell {
        background: $table-hover-bg;
        border-color: $table-hover-bg;
    }
}

.jsgrid-header-row {
    > .jsgrid-header-cell {
        background: $table-head-bg;
        text-align: center !important;
    }
}

.jsgrid-filter-row {
    > .jsgrid-cell {
        background: lighten($table-head-bg, 2%);
    }
}

.jsgrid-edit-row>.jsgrid-cell,
.jsgrid-insert-row>.jsgrid-cell {
    background: $gray-100;
}

.jsgrid input,
.jsgrid select,
.jsgrid textarea {
    border: 1px solid $gray-100;
    padding: .4em .6em;
    outline: none !important;
}

.jsgrid-pager-container {
    margin-top: 10px;
}

.jsgrid-pager-page {
    padding: 0;
    margin: 0 2px;
    &.jsgrid-pager-current-page {
        background-color: $primary;
        color: $white;
    }
}

.jsgrid-pager-page a,
.jsgrid-pager-current-page {
    background-color: $gray-100;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: inline-block;
    text-align: center;
    line-height: 24px;
    color: $gray-700;
}

.jsgrid-pager-nav-button {
    a {
        color: $gray-700;
        font-weight: 600;
        &:hover {
            color: $primary;
        }
    }
}

.jsgrid {
    .jsgrid-button {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-image: url("../images/jsgrid.png");
        background-color: $gray-200;
        outline: none !important;
    
        &:hover {
            opacity: 0.9;
            background-color: $gray-100;
        }
    }
}

.jsgrid-search-mode-button {
    background-position: 0 -295px;
}

.jsgrid-insert-button {
    background-position: 0 -160px;
}

.jsgrid-header-sort {
    &:before {
        position: absolute;
    }
}